import { 
    faBars, 
    faCoffee,
    faUtensils,
    faShoppingCart,
    faDownload,
    faDesktop,
    faLink,
    faGlobe,
} from '@fortawesome/free-solid-svg-icons';

import {
    faClipboard,
    faCalendarCheck,
    faEnvelope
} from '@fortawesome/free-regular-svg-icons';

import {
    faFacebook,
    faInstagram,
    faTwitter,
    faYoutube,
    faWhatsapp,
    faLinkedin,
    faPinterest,
    faSnapchat,
    faTiktok,
    faTelegram,
    faSpotify,
    faSoundcloud,
    faGithub,
    faTwitch,
    faBehance,
} from '@fortawesome/free-brands-svg-icons';
  
const iconMap = {
    'faGlobe': faGlobe,
    'faDesktop': faDesktop,
    'faLink': faLink,
    'faFacebook' : faFacebook,
    'faInstagram' : faInstagram,
    'faTwitter' : faTwitter,
    'faBars': faBars,
    'faCoffee': faCoffee,
    'faUtensils': faUtensils,
    'faShoppingCart': faShoppingCart,
    'faYoutube': faYoutube,
    'faDownload': faDownload,
    'faWhatsapp': faWhatsapp,
    'faLinkedin': faLinkedin,
    'faPinterest': faPinterest,
    'faSnapchat': faSnapchat,
    'faTiktok': faTiktok,
    'faTelegram': faTelegram,
    'faSpotify': faSpotify,
    'faSoundcloud': faSoundcloud,
    'faGithub': faGithub,
    'faTwitch': faTwitch,
    'faCalendar': faCalendarCheck,
    'faBehance': faBehance,
    'faClipboard': faClipboard,
    'faEnvelope':faEnvelope,
}

export default iconMap;
  